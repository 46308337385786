.App {
  text-align: center;
  color: #bbb;
  overflow-x: hidden;
  background: linear-gradient(#282c34, #17191e, rgb(18, 18, 19) 80%);
}

.App-header {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav {
  position: fixed;
  top:0;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #bbb;
  background: rgba(18, 18, 19, 0.3);
  z-index: 1;
}

.nav img {
  height: 80px;
  width: 80px;
  padding-left: 50px;
}

.nav ul {
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
  list-style-type: none;
  width: 80%;
}

.nav ul li a {
  color: #bbb;
  text-decoration: none;
  font-weight: 700;
}

.nav > .icons {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  padding-left: 15px;
  font-size: 30px;
}

.nav > .icons img{
  width: 20px;
  height: 20px;
  margin: 10px;
  padding-left: 0;
}

.home {
  width: 100%;
  background-image: url('./images/planets.webp');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-title {
  margin-top: 20vh;
  margin-left: 30vw;
  line-height: 0.6;
}

.home-title h2 {
  font-size: 4.5vw;
  letter-spacing: 2px;
  margin-bottom: -15px;
  z-index: 1;
}

.home-title h3 {
  font-family: "magneto-extended", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  color: rgba(225, 54, 182, 0.7); 
}

.home-text {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 70%;
  border-radius: 20px;
  background: linear-gradient(rgba(23, 25, 30, 0.7), rgba(18, 18, 19, 0.7));
  border-top: #10312f 1px solid;
  margin-top: 10vh;
}

.home p {
  margin: 5px 60px 20px 60px;
}

.display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10vh
}

.gallery {
  box-sizing:border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 70px 1fr 50px;
  border: solid 5px #2b807b;
  border-radius: 20px;
  width: 98%;
  margin-bottom: 10vh;
  background-color: #141516
}

.gallery h5 {
  grid-column: 1/4;
  display: grid;
  justify-content: flex-end;
  padding-right: 40px;
}

.gallery a {
  color: #bbb;
  text-decoration: none;
}

.item-title {
  grid-row: 1/2;
  grid-column: 1/4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
}

.item {
  display: grid;
  grid-template-rows: 1fr 30px;
  margin-top: 30px;
  line-height: 1;
}

.item img {
  width: 50%;
  margin: auto auto;
}

.item a img {
  width: 100px;
  margin: auto auto;
}

.item h4 {
  font-size: 1rem;
}

.designs {
  box-sizing:border-box;
  border: solid 5px #2b807b;
  border-radius: 20px;
  width: 98%;
  background-color: #141516;
  display: flex;
  justify-content: center;
}

.designs1 {
  box-sizing:border-box;
  border: solid 5px #2b807b;
  border-radius: 20px;
  width: 98%;
  background-color: rgba(253, 251, 243, 0.2);
  display: flex;
  justify-content: center;
}

.designs > .item {
  width: 30%;
  padding: 40px 0;
}

.contain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.contact-box {
  box-sizing: content-box;
}

.contact-box > .contact {
  width: 50%;
  margin: auto auto;
}

.form form{
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.form label {
  text-align: left;
  padding: 0 2px;
}

.form input {
  background-color: #2b807b;
}

.form textarea {
  height: 100px;
  padding: 5px;
  margin-bottom: 5px;
  background-color: #2b807b;
}

.form button {
  border-radius: 5px;
  width: 150px;
  font-family: 'Ysabeau SC', 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin: auto auto;
  background-color: #bbb;
}

.form input {
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
}

.footer {
  width: 100%;
}

.footer > .footer-main {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.footer img {
  width: 200px;
  padding: 10px 0;
}

.footer > .footer-links {
  position: fixed;
  bottom: 0px;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.footer > .footer-links ul {
  display: flex;
  list-style-type: none;
  padding-right: 40px;
}

.footer > .footer-links ul li a{
  padding-left: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #bbb;
  text-decoration: none;
}