@media screen and (max-width: 750px) {
    .home-title {
        line-height: 1;
    }

    .home-title h3 {
        font-size: 0.9rem;
        color: rgba(225, 54, 182, 0.7); 
    }
}

@media screen and (max-width: 420px) {
    .nav ul {
        width: 60%;
    }
    
    .nav ul li a {
        font-size: 1rem;
    }
    
    .nav img {
        height: 60px;
        width: 60px;
        padding-left: 25px;
      }

    .home-title {
        margin-top: 15vh;
        margin-left: 30vw;
    }
      
    .home-title h2 {
        font-size: 2rem;
        z-index: 1;
        line-height: 0.9;
    }
      
    .home-title h3 {
        font-family: "magneto-extended", sans-serif;
        font-weight: 700;
        color: rgba(225, 54, 182, 0.7); 
    }

    .home-text {
        font-size: 0.8rem;
    }

    .gallery {
        display: flex;
        flex-direction: column;
    }

    .contain {
        display: flex;
        flex-direction: column;
    }

    .item {
        padding-bottom: 20px;
    }
}