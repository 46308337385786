@import url('https://fonts.googleapis.com/css2?family=Ysabeau+SC:wght@100;200;300;400;500;600;700;800;900;1000&display=swap');
@import url("https://use.typekit.net/uxx7uvg.css");

body {
  margin: 0;
  padding: 0;
  font-family: 'Ysabeau SC', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

